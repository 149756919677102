import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import TextContainer from '../components/ui-components/containers/text-container'
import Button from '../components/ui-components/button'
import { Link } from 'gatsby'

const Danke = ({}) => {
  return (
    <Layout>
      <TextContainer>
        <div className="text-center">
          <h2 className="font-handwritten text-5xl mt-24">
            Die angeforderte Seite existiert nicht
          </h2>
          <div className="text-3xl mt-4">
            <Button type="primary">
              <Link to={'/'}>Zur Startseite</Link>
            </Button>
          </div>
        </div>
      </TextContainer>
    </Layout>
  )
}

Danke.propTypes = {}

export default Danke
